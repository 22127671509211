import React from "react"
import { graphql } from "gatsby"
import TourView from "../../views/tour-view"

function Tour(props) {
  const { googleTourenSheet } = props.data
  return <TourView tour={googleTourenSheet} />
}

export default Tour

export const query = graphql`
  query($id: String!) {
    googleTourenSheet(id: { eq: $id }) {
      titleDe
      titleEn
      slugDe
      schleusen
      fahrzeit
      fahrzeitEinheitDe
      fahrzeitEinheitEn
      textDe
      textEn
      featuredImage
    }
  }
`