import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";

import Hero from "../components/hero";
import useTranslations from "../hooks/useTranslations";
import { LocaleContext } from "../components/layout";

// markup
const TourView = (tour) => {
  const { locale } = React.useContext(LocaleContext);
  const { abbreviation, discoverNow, tourPage } = useTranslations();
  const data = useStaticQuery(graphql`
    {
      tourImages: allFile(
        filter: { sourceInstanceName: { eq: "tourImages" } }
      ) {
        nodes {
          base
          childImageSharp {
            gatsbyImageData(width: 1920)
          }
        }
      }
    }
  `);
  const t = tour.tour;
  const index = data.tourImages.nodes.findIndex(
    (boatImage) => boatImage.base === `${t.featuredImage}.jpg`
  );
  const image = getImage(data.tourImages.nodes[index]);
  const heroHeightScreen = false; // true set height to 100vh
  const title = locale === "de" ? t.titleDe : t.titleEn;
  const description = locale === "de" ? t.textDe : t.textEn;
  const fahrzeitEinheit =
    locale === "de" ? t.fahrzeitEinheitDe : t.fahrzeitEinheitEn;
  return (
    <>
      <Helmet>
        <title>{`${title} \\ ${abbreviation.approx} ${t.fahrzeit} ${fahrzeitEinheit} \\ Boot online mieten Berlin`}</title>
      </Helmet>
      <>
        <Hero
          hScreen={heroHeightScreen}
          heroTextAlignmentCenter={true}
          preTitle={`${discoverNow}:`}
          title={`${title}`}
          heroImg={image}
        />
        <div className="grid max-w-4xl gap-6 px-6 py-16 mx-auto lg:gap-12 md:grid-cols-4 lg:grid-cols-5">
          <div className="md:col-span-3 lg:col-span-4">
            <ReactMarkdown>{description}</ReactMarkdown>
          </div>
          <div>
            {(t.fahrzeit || t.schleusen) && (
              <div className="mb-10">
                <h3 className="text-sm font-medium text-gray-900">
                  {tourPage.keyData}
                </h3>

                <div className="mt-4">
                  <ul className="pl-4 space-y-2 text-sm list-disc">
                    {t.fahrzeit && (
                      <li className="text-gray-400">
                        <span className="text-gray-600">{`${tourPage.journeyTime}: ${abbreviation.approx} ${t.fahrzeit} ${fahrzeitEinheit}`}</span>
                      </li>
                    )}
                    {t.schleusen ? (
                      <li className="text-gray-400">
                        <span className="text-gray-600">{`${tourPage.locks}`}</span>
                      </li>
                    ) : (
                      <li className="text-gray-400">
                        <span className="text-gray-600">{`${tourPage.noLocks}`}</span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default TourView;
